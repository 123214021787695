import { usePage } from '@uidu/api.js/react';
import FieldPassword from '@uidu/field-password';
import FieldText from '@uidu/field-text';
import Form, { FormSubmit } from '@uidu/form';
import { Button, MaxWidthContent } from 'components/Base';
import SignupPage from 'layouts/SignupPage';
import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';

export default function Login() {
  const { page, loading, error } = usePage({ slug: 'login' });
  const router = useRouter();

  return (
    <SignupPage loading={loading}>
      <div>
        <MaxWidthContent>
          <div tw="flex flex-1 items-center justify-center my-10">
            <div tw="rounded-lg px-16 py-20 lg:max-w-xl sm:max-w-md w-full mb-3 bg-white shadow-lg">
              <h1 tw="font-bold text-lg lg:text-4xl my-2 sm:mb-2">Accedi</h1>
              <p tw="mb-8 text-lg">
                Beatrice è un progetto per le persone della Valle Seriana. Puoi
                entrare nella Community di Beatrice come Comune, Azienda,
                Esercizi Commmerciali, Ente del Terzo settore o cittadino, basta
                una email!
              </p>
              <Form
                tw=""
                handleSubmit={async (model) => {
                  const result = await signIn('credentials', {
                    redirect: false,
                    password: model.password,
                    email: model.email,
                  });
                  if (result.error) {
                    window.alert(result.error);
                  } else {
                    router.push('/');
                  }
                }}
                footerRenderer={(props) => (
                  <FormSubmit
                    forwardedAs={Button}
                    tw="mt-8"
                    type="submit"
                    shouldFitContainer
                    {...props}
                  >
                    Avanti
                  </FormSubmit>
                )}
              >
                <div tw="space-y-8">
                  <FieldText
                    label="Email *"
                    placeholder="Email *"
                    type="email"
                    name="email"
                  />
                  <FieldPassword
                    label="Password *"
                    placeholder="Password *"
                    name="password"
                  />
                </div>
              </Form>
              {/* </div> */}
              <div tw="mt-6">
                <a href="#" tw="hover:underline">
                  Hai dimenticato la password?
                </a>
              </div>
              <div tw="my-2">
                <span>Non hai un account?</span>
                <a
                  href="/register"
                  tw="text-base font-semibold hover:text-secondary"
                >
                  {' '}
                  Registrati
                </a>
              </div>
            </div>
            {/* <div tw="mx-16 flex flex-col">
              <Button tw="uppercase rounded-lg bg-green-500 mt-5">
                <p>Accedi con Welfare Aziendale</p>
              </Button>
              <Button tw="uppercase rounded-lg bg-green-500 mt-5">
                <p>Accedi con Facebook</p>
              </Button>
              <Button tw="uppercase rounded-lg bg-green-500 mt-5">
                <p>Accedi con Google</p>
              </Button>
            </div> */}
          </div>
        </MaxWidthContent>
      </div>
    </SignupPage>
  );
}
