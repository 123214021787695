import { SignupNavigation } from 'components/Blocks';
import { data } from 'data/signupNavigation.data';
import tw from 'twin.macro';

const Main = tw.main`relative w-full overflow-hidden mt-20 bg-secondary h-screen`;

const SignupPage: React.FC = ({ children }) => {
  return (
    <>
      <Main>
        <SignupNavigation navigation={data.blocks[0]} />
        {children}
        {/* <Footer /> */}
      </Main>
    </>
  );
};

export default SignupPage;
